import React, { createRef, useEffect, useMemo, useRef, useState } from 'react';
import {
  TableContainer as Container,
  Table as MuiTable,
  TableHead as Head,
  TableBody as Body,
  TableRow as Row,
  useTheme,
  Box,
  Divider,
  InputAdornment,
  Button,
  LinearProgress,
  Typography,
  TableCell
} from '@material-ui/core';
import Cell from './components/Cell';
import { useStyles } from './hooks';
import { generateTotals, generateExportableData, getCellValue } from './utils';
import DebounceTextField from '../DebounceTextField';
import { CloudDownload, Search } from '@material-ui/icons';
import { jsonToExcel } from 'src/utils';
import { TblFilter } from './components';
import { escapeRegExp, get } from 'lodash';

/**
 * Modifies the given columns array to make all columns before the last sticky
 * column sticky as well, and removes hidden columns.
 *
 * @param {Array<column>} columns The array of column definitions, each with a
 *   sticky property.
 * @return {{columns:Array<column>,lastStickyIndex:number}} A new array with the same columns, but with the sticky
 *   property modified according to the above rule, and without any hidden
 *   columns.
 */
function filterColumns(columns) {
  const res = [];

  let stickyFound = false,
    lastStickyIndex = -1;
  for (let i = columns.length - 1; i >= 0; i--) {
    if (!columns[i]?.hide) {
      if (!stickyFound && columns[i]?.sticky) {
        stickyFound = true;
        lastStickyIndex = i;
      }

      res.unshift(stickyFound ? { ...columns[i], sticky: true } : columns[i]);
    }
  }

  return { columns: res, lastStickyIndex };
}

function boxShadow(value) {
  return value ? '4px 0 6px rgba(0, 0, 0, 0.1)' : 'none';
}

function getCellStyles(col, left, extraStyles = {}) {
  return {
    width: col?.width || 200,
    minWidth: col?.width ? col.width : col?.minWidth || 200,
    position: col?.sticky ? 'sticky' : 'static',
    zIndex: col?.sticky ? 15 : 10,
    left,
    ...extraStyles
  };
}

function filterTableData(data = [], filters = {}, searchFilter = '') {
  const searchRegEx = new RegExp(escapeRegExp(searchFilter), 'i');

  return data.filter(item => {
    for (const key of Object.keys(filters)) {
      if (filters[key] !== 'all' && item[key] !== filters[key]) {
        return false;
      }
    }

    return Object.keys(item).some(field =>
      searchRegEx.test((item?.[field] || '').toString())
    );
  });
}

/**
 *
 * @param {object} param
 * @param {column[]} param.columns
 * @param {string} param.rowIDKey
 * @param {any[]} param.data
 * @param {string[]} param.totals
 * @param {boolean} param.hideToolbar
 * @param {{show:boolean,fields:string[]}} param.search
 * @param {{show:boolean,fields:string[],prepend:string[],showNo:boolean,includeTotals:boolean}} param.dataExport
 * @param {string} param.title
 * @param {{left: React.JSX.Element, right: React.JSX.Element}} param.toolbar
 * @param {boolean} param.isLoading
 * @param {(params:cellParams,event:React.MouseEventHandler<HTMLTableCellElement>)=>void} param.onCellClick
 * @param {(params:cellParams,event:React.MouseEventHandler<HTMLTableCellElement>)=>void} param.onCellDoubleClick
 * @param {(params:cellParams,event:React.FocusEvent<HTMLInputElement>)=>void} param.onCellEdit
 * @returns
 */
const Table = ({
  columns = [],
  data = [],
  rowIDKey = 'id',
  totals = [],
  hideToolbar = false,
  search = { show: true, fields: [] },
  dataExport = {
    show: true,
    fields: [],
    prepend: [],
    showNo: false,
    includeTotals: false
  },
  title = '',
  toolbar = {
    left: null,
    right: null
  },
  isLoading = false,
  maxHeight = '100%',
  onCellClick = () => {},
  onCellDoubleClick = () => {},
  onCellEdit = () => {}
}) => {
  const theme = useTheme();
  const classNames = useStyles();

  const tableFilters = columns.filter(col => col?.filter?.allow);

  const { columns: shownColumns, lastStickyIndex } = filterColumns(columns);
  const columnsTotals = generateTotals(totals, data);
  const [cellRefs, setCellRefs] = useState([]);
  const [scrolled, setScrolled] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');
  const [filterValues, setFilterValues] = useState({});

  const colLeft = useMemo(() => {
    const res = [];

    let temp = 0;

    for (const [index, col] of shownColumns.entries()) {
      res.push(col?.sticky && index >= 0 ? temp + 'px' : 'auto');
      temp += col?.width || 200;
    }

    return res;
  }, [shownColumns]);

  const shownData = filterTableData(data, filterValues, searchQuery);
  const maxCol = shownColumns.length;
  const maxRow = shownData.length;

  useEffect(() => {
    setCellRefs(
      Array.from({ length: maxRow }, () =>
        Array.from({ length: maxCol }, () => createRef())
      )
    );
  }, [maxCol, maxRow]);

  function focusCell(rowIndex, colIndex) {
    if (
      colIndex < 0 ||
      colIndex > maxCol - 1 ||
      rowIndex < 0 ||
      rowIndex > maxRow - 1
    )
      return;

    if (cellRefs[rowIndex][colIndex].current) {
      cellRefs[rowIndex][colIndex].current.focus();
    }
  }

  function updateFilters(name, value) {
    setFilterValues(state => ({ ...state, [name]: value }));
  }

  function exportData() {
    jsonToExcel({
      data: [
        {
          data: generateExportableData(data, columns, {
            showNo: dataExport?.showNo || false,
            includeTotals: dataExport?.includeTotals || false,
            totals: columnsTotals
          }),
          options: { skipHeader: true }
        }
      ],
      fileName: title
    });
  }

  return (
    <Box>
      {!hideToolbar && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={2}
        >
          <Box display="flex" alignItems="center" gridGap={theme.spacing(1)}>
            {search.show && (
              <DebounceTextField
                variant="outlined"
                size="small"
                placeholder="Search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  )
                }}
                value={searchQuery}
                onChange={e => setSearchQuery(e.target.value)}
              />
            )}
            {toolbar.left}
          </Box>
          <Box display="flex" alignItems="center" gridGap={theme.spacing(1)}>
            {toolbar.right}
            {dataExport.show && (
              <Button
                size="small"
                startIcon={<CloudDownload />}
                variant="contained"
                color="primary"
                onClick={exportData}
              >
                Export
              </Button>
            )}
          </Box>
        </Box>
      )}
      {isLoading && <LinearProgress style={{ height: 2 }} />}
      <Divider />
      {Boolean(tableFilters?.length) && (
        <>
          <TblFilter
            filters={tableFilters}
            filterValues={filterValues}
            updateFilters={updateFilters}
            data={data}
          />
          <Divider />
        </>
      )}
      <Container
        style={{ maxHeight }}
        onScroll={event => {
          setScrolled(event.target.scrollLeft > 15);
        }}
      >
        <MuiTable
          className={classNames.alternatingRow}
          style={{
            border: 'none',
            borderCollapse: 'separate',
            borderSpacing: 0
          }}
        >
          <Head>
            <Row
              style={{
                position: 'sticky',
                top: 0,
                zIndex: 20
              }}
            >
              {shownColumns.map((col, colIndex) => (
                <TableCell
                  key={col.field}
                  className={classNames.header}
                  style={{
                    width: col?.width || 200,
                    minWidth: col?.width ? col.width : col?.minWidth || 200,
                    textAlign: col?.headerAlign || 'left',
                    position: col.sticky ? 'sticky' : 'static',
                    backgroundColor: theme.palette.background.paper,
                    zIndex: col.sticky ? 15 : 10,
                    left: colLeft[colIndex],
                    boxShadow: boxShadow(
                      colIndex === lastStickyIndex && scrolled
                    )
                  }}
                >
                  {col.headerName}
                </TableCell>
              ))}
            </Row>
          </Head>

          <Body>
            {shownData.map((item, rowIndex) => (
              <Row
                key={item[rowIDKey]}
                style={{
                  border: 'none',
                  borderBottom: '1px solid #C4C4C4'
                }}
              >
                {shownColumns.map((col, colIndex) => (
                  <Cell
                    key={col.field}
                    onCellDoubleClick={onCellDoubleClick}
                    onCellClick={onCellClick}
                    onCellEdit={onCellEdit}
                    focusCell={focusCell}
                    className={classNames.cellDense}
                    align={col?.align || 'left'}
                    style={getCellStyles(col, colLeft[colIndex], {
                      boxShadow: boxShadow(
                        colIndex === lastStickyIndex && scrolled
                      )
                    })}
                    col={col}
                    item={item}
                    rowIDKey={rowIDKey}
                    rowIndex={rowIndex}
                    colIndex={colIndex}
                    cellRef={cellRefs?.[rowIndex]?.[colIndex]}
                  />
                ))}
              </Row>
            ))}
            {totals.length > 0 && shownData.length > 0 && (
              <Row
                style={{
                  position: 'sticky',
                  bottom: 0,
                  zIndex: 20
                }}
              >
                {shownColumns.map((col, colIndex) => (
                  <TableCell
                    key={col.field}
                    align={col?.align || 'left'}
                    className={classNames.cellDense}
                    style={getCellStyles(col, colLeft[colIndex], {
                      borderTop: '2px solid #C4C4C4',
                      fontWeight: 700,
                      boxShadow: boxShadow(
                        colIndex === lastStickyIndex && scrolled
                      )
                    })}
                  >
                    {totals.includes(col.field)
                      ? getCellValue({
                          col,
                          row: {},
                          id: 'total',
                          value: columnsTotals[col.field],
                          field: col.field,
                          hasFocus: false
                        })
                      : ''}
                  </TableCell>
                ))}
              </Row>
            )}
          </Body>
        </MuiTable>
      </Container>
      {shownData.length === 0 && (
        <Box
          height={52}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="body2">No Data</Typography>
        </Box>
      )}
    </Box>
  );
};
export default React.memo(Table);
